import moment from "moment"
import Shift from "interfaces/Shift"
import Worker from "interfaces/Worker"
import { executeRequest, RequestType } from "../../api/APIUtils"
import endpoints from "../../api/endpoints"

interface ShiftRequest {
    id: number
    workerId: number
    day: string
    time: string
    reason?: string
}

interface ShiftResponse {
    success?: boolean
    shift?: Shift
    errors?: string[]
}

interface ActionLabel {
    default: string
    loading: string
    confirmation: string
}

export type ActionResult = { error?: string; newShift?: Shift; confirmation?: string }

type ActionHandler = (shift: Shift, worker: Worker, reason?: string) => Promise<ActionResult>

const formattedNow = (timezoneId: string) => {
    const now = moment.tz(timezoneId)
    return {
        day: now.format("DD-MM-YYYY"),
        time: now.format("HH:mm")
    }
}

const startShift: ActionHandler = async (shift, worker) => {
    const startShiftRequest: ShiftRequest = {
        id: shift.id,
        workerId: worker.id,
        ...formattedNow(shift.instanceTimezoneId)
    }

    try {
        const { success, shift: newShift, errors }: ShiftResponse = await executeRequest({
            endpoint: endpoints.worker.START_SHIFT,
            withApiKey: true,
            requestType: RequestType.POST,
            params: startShiftRequest
        })
        if (success && newShift) {
            return {
                newShift: {
                    ...shift,
                    actStartTime: newShift.actStartTime
                }
            }
        } else {
            throw new Error(errors?.join(", "))
        }
    } catch (error: any) {
        return { error: error.message }
    }
}

const startBreak: ActionHandler = async (shift, worker) => {
    const startBreakRequest: ShiftRequest = {
        id: shift.id,
        workerId: worker.id,
        ...formattedNow(shift.instanceTimezoneId)
    }

    try {
        const { success, shift: newShift, errors }: ShiftResponse = await executeRequest({
            endpoint: endpoints.worker.START_BREAK,
            withApiKey: true,
            requestType: RequestType.POST,
            params: startBreakRequest
        })
        if (success && newShift) {
            return {
                newShift: {
                    ...shift,
                    canStopShift: false,
                    breakStartTime: newShift.breakStartTime
                }
            }
        } else {
            throw new Error(errors?.join(", "))
        }
    } catch (error: any) {
        return { error: error.message }
    }
}

const stopBreak: ActionHandler = async (shift, worker) => {
    const stopBreakRequest: ShiftRequest = {
        id: shift.id,
        workerId: worker.id,
        ...formattedNow(shift.instanceTimezoneId)
    }

    try {
        const { success, shift: newShift, errors }: ShiftResponse = await executeRequest({
            endpoint: endpoints.worker.STOP_BREAK,
            withApiKey: true,
            requestType: RequestType.POST,
            params: stopBreakRequest
        })
        if (success && newShift) {
            return {
                newShift: {
                    ...shift,
                    breakStopTime: newShift.breakStopTime
                }
            }
        } else {
            throw new Error(errors?.join(", "))
        }
    } catch (error: any) {
        return { error: error.message }
    }
}

const stopShift: ActionHandler = async (shift, worker, reason?): Promise<ActionResult> => {
    const stopShiftRequest: ShiftRequest = {
        id: shift.id,
        workerId: worker.id,
        ...formattedNow(shift.instanceTimezoneId),
        reason
    }

    try {
        const { success, shift: newShift, errors }: ShiftResponse = await executeRequest({
            endpoint: endpoints.worker.STOP_SHIFT,
            withApiKey: true,
            requestType: RequestType.POST,
            params: stopShiftRequest
        })
        if (success && newShift) {
            return {
                newShift: {
                    ...shift,
                    actStopTime: newShift.actStopTime
                }
            }
        } else {
            throw new Error(errors?.join(", "))
        }
    } catch (error: any) {
        return { error: error.message }
    }
}

export const actionLabels: { [key: string]: ActionLabel } = {
    START_SHIFT: {
        default: "startShift",
        loading: "startingShift",
        confirmation: "shiftStartSubmittedSuccessfully"
    },
    STOP_SHIFT: {
        default: "stopShift",
        loading: "stoppingShift",
        confirmation: "shiftStopSubmittedSuccessfully"
    },
    START_BREAK: {
        default: "startBreak",
        loading: "startingBreak",
        confirmation: "shiftBreakStartSubmittedSuccessfully"
    },
    STOP_BREAK: {
        default: "stopBreak",
        loading: "stoppingBreak",
        confirmation: "shiftBreakStopSubmittedSuccessfully"
    },
    NO_ACTION: {
        default: "",
        loading: "loadingAction",
        confirmation: ""
    }
}

export const getActionLabel = (labels: ActionLabel, loading: boolean): string => {
    return labels[loading ? "loading" : "default"]
}

export type Action = {
    action: string
    handler: ActionHandler
    labels: ActionLabel
}

export function getHandlerForActionName(action: string): Action {
    switch (action) {
        case "START_SHIFT":
            return { action: "START_SHIFT", handler: startShift, labels: actionLabels.START_SHIFT }
        case "START_BREAK":
            return { action: "START_BREAK", handler: startBreak, labels: actionLabels.START_BREAK }
        case "STOP_BREAK":
            return { action: "STOP_BREAK", handler: stopBreak, labels: actionLabels.STOP_BREAK }
        case "STOP_SHIFT":
            return { action: "STOP_SHIFT", handler: stopShift, labels: actionLabels.STOP_SHIFT }
        default:
            return {
                action: "NO_ACTION",
                handler: () =>
                    new Promise(resolve => {
                        resolve({ error: "Unknown action" })
                    }),
                labels: actionLabels.NO_ACTION
            }
    }
}
