import React, { useEffect } from "react"
import clsx from "clsx"

import "./TimerButton.scss"

type Props = {
    text: string
    onClick: () => void
    autoClickInMs?: number
    loading?: boolean
    disabled?: boolean
    className?: string
}

export default function TimerButton({ text, onClick, autoClickInMs, loading, disabled, className }: Props) {
    const timerOn = autoClickInMs !== undefined && !(disabled || loading)

    useEffect(() => {
        if (timerOn) {
            const timeoutId = setTimeout(onClick, autoClickInMs)
            return () => clearTimeout(timeoutId)
        }
    }, [timerOn, autoClickInMs, onClick])

    return (
        <button
            className={clsx("TimerButton button bigger", className, { timer: timerOn })}
            onClick={onClick}
            style={{ animation: timerOn ? `timer ${autoClickInMs}ms linear` : "none" }}
            disabled={loading || disabled}
        >
            {loading && <div className="spinner" />}
            <div className="text">{text}</div>
        </button>
    )
}
