import usePortal from "hooks/usePortal";
import React, { useRef, PropsWithChildren, useEffect } from "react";
import ReactDOM from "react-dom";

interface Props {
    onRequestClose: () => void
}

export default function Modal({ onRequestClose, children }: PropsWithChildren<Props>) {
    const modalRef = useRef<HTMLDivElement>(null);
    const modalsPortal = usePortal("modals")

    const handleDocumentClick = (e: any) => {
        if (modalRef && modalRef.current && !modalRef.current.contains(e.target)) {
            onRequestClose()
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick, false)

        return () => {
            document.removeEventListener("click", handleDocumentClick, false)
        };
    }, []);

    return ReactDOM.createPortal((
        <div>
            <div className="modal-backdrop show" />

            <div className="modal-container">
                <div className="modal" ref={modalRef}>
                    {children}
                </div>
            </div>
        </div>
    ), modalsPortal)
}
