import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"

import Shift from "interfaces/Shift"
import Worker from "interfaces/Worker"
import { useHistory } from "react-router-dom"
import useAvatar from "hooks/useAvatar"
import MessageBlock from "components/ShiftFound/MessageBlock"
import ShiftInfo from "components/ShiftFound/ShiftInfo"
import useFeatureSwitches from "hooks/useFeatureSwitches"
import { AuthContext } from "providers/AuthProvider"
import Clock from "components/Clock"
import Countdown from "components/Countdown"
import LoaderButton from "components/widgets/LoaderButton"
import { Action, getActionLabel, getHandlerForActionName } from "./actions"
import ConfirmationNotice from "components/ShiftFound/ConfirmationNotice"
import { AppContext, FeedbackType } from "providers/AppProvider"
import StoppingShiftEarlyReasonModal from "components/StoppingShiftEarlyReasonModal"
import { asInstanceTzMoment } from "components/timezone/TimezoneConversion"
import moment from "moment"
import requiresReasonToStopEarly from "components/reasonToStopEarly"

type Props = {
    shift: Shift
    worker: Worker
}

export default function TouchQR({ shift, worker }: Props) {
    const history = useHistory()
    const { setFeedback } = useContext(AppContext)
    const { Message, operationalModeConfig } = useContext(AuthContext)
    const avatar = useAvatar(worker)
    const { timezonesEnabled, earlyStopReasonEnabled, loadingFeatureSwitches } = useFeatureSwitches()

    const [modifiedShift, setModifiedShift] = useState<Shift>(shift)
    const [beingExecuted, setBeingExecuted] = useState<Action | undefined>()
    const [wasExecuted, setWasExecuted] = useState<Action | undefined>()
    const [isDone, setIsDone] = useState<boolean>(false)
    const [showReasonModal, setShowReasonModal] = useState<boolean>(false)

    const resetCountdown = operationalModeConfig?.resetCountdown || 5

    // TODO: generalise this, extract to a hook
    const executeAction = useCallback(
        async (action: Action, shift: Shift, reason?: string) => {
            setBeingExecuted(action)
            const response = await action.handler(shift, worker, reason)
            if (response.newShift) {
                setModifiedShift(response.newShift)
                setWasExecuted(action)
            } else if (response.error) {
                setFeedback({
                    type: FeedbackType.ERROR,
                    message: Message(response.error)
                })
            }
            setIsDone(true)
            setBeingExecuted(undefined)
            setShowReasonModal(false)

            return () => {}
        },
        [setFeedback, worker, Message]
    )

    // execute action automatically if possible
    useEffect(() => {
        if (!loadingFeatureSwitches) {
            if (!isDone && !beingExecuted) {
                const action = getHandlerForActionName(shift.action)
                if (requiresReasonToStopEarly(action, shift, earlyStopReasonEnabled)) {
                    setShowReasonModal(true)
                    return
                }
                if (["START_SHIFT", "STOP_BREAK", "STOP_SHIFT"].includes(shift.action)) {
                    executeAction(action, shift)
                }
            }
        }
        return () => {}
    }, [shift, executeAction, beingExecuted, isDone, earlyStopReasonEnabled, loadingFeatureSwitches])

    const executeStopActionManually = (stopAction: Action, modifiedShift: Shift) => {
        if (!loadingFeatureSwitches && requiresReasonToStopEarly(stopAction, modifiedShift, earlyStopReasonEnabled)) {
            setShowReasonModal(true)
            return
        } else {
            executeAction(stopAction, modifiedShift)
        }
    }

    const nextAction = useMemo(() => getHandlerForActionName(modifiedShift.action), [modifiedShift.action])

    const secondActionPossible = nextAction.action === "START_BREAK" && modifiedShift.canStopShift

    const stopShiftAction = getHandlerForActionName("STOP_SHIFT")
    const stopActionPossibleForSecondAction: Action | undefined = useMemo(
        () => (secondActionPossible ? stopShiftAction : undefined),
        [secondActionPossible]
    )

    const nextActionLoading = beingExecuted ? beingExecuted.action === nextAction.action : false
    const stopActionLoading = beingExecuted ? beingExecuted.action === stopActionPossibleForSecondAction?.action : false

    const loading = nextActionLoading || stopActionLoading || loadingFeatureSwitches

    if (isDone && secondActionPossible && wasExecuted) {
        return (
            <ConfirmationNotice
                message={Message(wasExecuted.labels.confirmation)}
                shift={modifiedShift}
                timezonesEnabled={timezonesEnabled}
                onRestart={history.goBack}
                autoRestartMs={resetCountdown * 1000}
            />
        )
    }

    return (
        <div className="shiftFound contentBox">
            {showReasonModal && (
                <StoppingShiftEarlyReasonModal
                    onRequestClose={() => {
                        setShowReasonModal(false)
                    }}
                    onConfirmReason={reason => executeAction(stopShiftAction, modifiedShift, reason)}
                    loading={loading}
                />
            )}
            <div className="field textAlignCenter">
                <div className="workerPhoto">{avatar}</div>

                <MessageBlock shift={modifiedShift} worker={worker} isDone={isDone} />

                <ShiftInfo shift={modifiedShift} timezonesEnabled={timezonesEnabled} />

                {!isDone && !shift.actStopTime && (
                    <div className="startOrStopShift">
                        <div className="time">
                            <div>{Message("timeNow")}</div>
                            <div className="larger">
                                <Clock
                                    toTz={modifiedShift.siteTimezoneId || modifiedShift.instanceTimezoneId}
                                    timezonesEnabled={timezonesEnabled}
                                />
                            </div>
                        </div>
                        <div className="action">
                            <LoaderButton
                                onClick={() => executeAction(nextAction, modifiedShift)}
                                loading={nextActionLoading}
                                disabled={stopActionLoading || nextActionLoading}
                                className="bigger"
                            >
                                {Message(getActionLabel(nextAction.labels, nextActionLoading))}
                            </LoaderButton>
                        </div>
                    </div>
                )}

                {!isDone && secondActionPossible && stopActionPossibleForSecondAction && (
                    <div className="secondaryAction">
                        <LoaderButton
                            onClick={() => executeStopActionManually(stopShiftAction, modifiedShift)}
                            loading={stopActionLoading}
                            disabled={stopActionLoading || nextActionLoading}
                            className="fullWidth bigger"
                        >
                            {Message(getActionLabel(stopActionPossibleForSecondAction.labels, stopActionLoading))}
                        </LoaderButton>
                    </div>
                )}

                {isDone && (
                    <div className={"bottomBar textAlignCenter"}>
                        <div className="contentBox">
                            <Countdown
                                initialCountdownSeconds={resetCountdown}
                                displayedMessage={wasExecuted !== undefined ? "stepAside" : "tryAgain"}
                                onCountdownComplete={history.goBack}
                            />
                        </div>
                    </div>
                )}

                {secondActionPossible && stopActionPossibleForSecondAction && (
                    <div className="bottomBar">
                        <div className="contentBox">
                            <button
                                className="grey bigger"
                                onClick={history.goBack}
                                disabled={nextActionLoading || stopActionLoading}
                            >
                                {Message("back")}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
