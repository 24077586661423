import moment, { Moment } from "moment"
import "moment-timezone"

export const SERVER_DATETIME_FORMAT = "DD-MM-YYYY HH:mm"

export const userTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone

export function asTzMoment(date: Moment, toTz: string | undefined, timezonesEnabled: boolean): Moment {
    return timezonesEnabled && toTz
        ? moment(date).clone().tz(toTz)
        : moment(date).clone()
}

export function asInstanceTzMoment(date: string | undefined, instanceTimezone: string): Moment | undefined {
    return date ? moment(date, SERVER_DATETIME_FORMAT).clone().tz(instanceTimezone, true) : undefined
}

export function instanceToTzMoment(
    date: string | undefined,
    toTz: string | undefined,
    instanceTimezone: string,
    timezonesEnabled: boolean
): Moment | undefined {
    return date
        ? timezonesEnabled && toTz
            ? asInstanceTzMoment(date, instanceTimezone)!.tz(toTz)
            : asInstanceTzMoment(date, instanceTimezone)
        : undefined
}
